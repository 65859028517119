//首页
import axios  from 'utils/axios'
import Qs from 'qs'

// /blade-user/userauth/save  post    是否授权
export const  userAuth=()=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/userauth/save';
        axios({
            url:url,
            method:"post",
        })
        .then((res)=>{
            var result = res;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
//获取门店信息    /blade-content/memberStoreInfo/getMemberStoreInfo
export const getMemberStoreInfo=()=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-content/memberStoreInfo/getMemberStoreInfo'; 
        axios.get(url,{
            //请求头配置  
            headers:{  'Content-Type':'application/x-www-form-urlencoded'},
       })
        .then((data)=>{
            resolve(data)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 切换门店账号
//切换门店账号第一步 app-pc 切换门店复制文档复制地址    POST    /blade-user/changeMemberStore
export const changeMemberStore=(memberStoreId)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/changeMemberStore';
        let data = {
            memberStoreId:memberStoreId,
        };
        axios({
            url:url,
            method:"post",
            data:Qs.stringify(data)
        })
        .then((res)=>{
            var result = res;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
// 切换门店账号第二步
export const RefreshLogin=(refresh_token)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-auth/oauth/token'; 
        let data = {
            grant_type:"refresh_token",
            scope:"all",
            refresh_token:refresh_token
        }
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
                'Authorization':'Basic M2FrLXBjOjNhay1wY19zZWNyZXQ=',
                'Tenant-Id':'104704',
                'User-Type':'pc'
            },
            method:"post",
            url:url,
            data:Qs.stringify(data)
        })
        .then((res)=>{
            var result = res;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 初始购物车数量   /ordercart/count

//   密码登录
export const Login=(username,password)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-auth/oauth/token'; 
        let data = {
            grant_type:"password",
            scope:"all",
            username:username,
            password:password
        }
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
                'Authorization':'Basic M2FrLXBjOjNhay1wY19zZWNyZXQ=',
                'Tenant-Id':'104704',
                'User-Type':'pc'
            },
            method:"post",
            url:url,
            data:Qs.stringify(data)
        })
        .then((res)=>{
            var result = res;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
// 验证码登录
export const vscodeLogin=(userphone,vscode)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-auth/oauth/token'; 
        let data = {
            grant_type:"phone_code",
            scope:"all",
            username:userphone,
            smsCode:vscode
        }
        axios({
            headers:{
                'Content-Type':'application/x-www-form-urlencoded',
                'Authorization':'Basic M2FrLXBjOjNhay1wY19zZWNyZXQ=',
                'Tenant-Id':'104704',
                'User-Type':'pc'
            },
            method:"post",
            url:url,
            data:Qs.stringify(data)
        })
        .then((res)=>{
            var result = res;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
// 登录手机获取验证码
export const sendCode=(phone)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-auth/oauth/sendCode';
        let data = {
            phone:phone,
        };
        axios({
            url:url,
            method:"post",
            data:Qs.stringify(data)
        })
        .then((res)=>{
            var result = res;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


// 查询账号首次授权平台   /blade-user/firstAuth   post  不传参
export const firstAuth=()=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-user/firstAuth'; 
        axios.post(url)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
//注册接口   post   /blade-user/register
// {
//     "account": "15303580915",
//     "idType": 2,       固定
//     "inviteCode": "1234",     邀请码
//     "password": "123456",
//     "smsCode": "121715",
//     "tenantId": "104704",     固定
//     "memberStore": "秦雪峰的店",
//     "address": "1",
//     "addressArea": "2",
//     "addressCity": "2",
//     "addressProvince": "1"
//    }
export const Register=(data)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/register';
        axios({
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Basic M2FrLXBjOjNhay1wY19zZWNyZXQ=',
                'Tenant-Id':'104704',
                'User-Type':'pc'
            },
            method:"post",
            url:url,
            data:JSON.stringify(data)
        })
        .then((res)=>{
            var result = res;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

//省市县地区接口调取   get  /blade-system/region/select
export const AreaPort=(code)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-system/region/select'; 
        axios.get(url,{
            params:{
                code:code
            }
        })
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

  //num 0注册    1改密码  2改手机号 
//注册 发送验证码   post   /blade-user/sendValidate
export const registerCode=(phone,num)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-user/sendValidate';
        let data = {
            phone:phone,
            type:num
        };
        axios({
            url:url,
            method:"post",
            data:Qs.stringify(data)
        })
        .then((res)=>{
            var result = res;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


//获取商品标签
  export const ProductTag=(key,val)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-business/producttag/getByParameter';
        let data = {
            key:key,
            value:val
        };
        axios({
            url:url,
            method:"post",
            data:Qs.stringify(data)
        })
        .then((res)=>{
            var result = res;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


//通过商品标签获取商品数据  /blade-business/producttag/getProductsByTagId    get     首页轮播下营销活动页面
export const TagProduct=(val)=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-business/producttag/getProductsByTagId';
        axios.get(url,{
            params:{
                tagId:val
            }
        })
        .then((res)=>{
            var result = res;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

// 头部导航栏    http://192.168.10.67:8000/api/blade-business/producttag/selectTagListByType?tagType=5
export const selectTagList=()=>{
    return new Promise((resolve,reject)=>{
        let url ='/api/blade-business/producttag/selectTagListByType';
        axios.get(url,{
            params:{
                tagType:1
            }
        })
        .then((data)=>{
            var result = data;
            return resolve(result)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}

//首页相关  pc 
//包含热销专区
//包含获取热销专区分类
export const frontList=()=>{      //0  pc
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-content/content/front/index'; 
        axios.get(url,{
            params:{
                channel:0    //0  pc
            }
        })
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


// 首页  banner返回接口  /blade-content/content/front/getHomeBanners
export const getHomeBanners=()=>{      //0  pc
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-content/content/front/getHomeBanners'; 
        axios.get(url,{
            params:{
                channel:0    //0  pc
            }
        })
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}
//首页 获取首页活动  /blade-content/content/front/getActivityOperation
export const getActivityOperation=()=>{      //0  pc
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-content/content/front/getActivityOperation'; 
        axios.get(url,{
            params:{
                channel:0    //0  pc
            }
        })
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}



//热销专区
//商品信息
export const hotShopInfo=(categoryId)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-content/content/front/getHotRecommendById'; 
        axios.get(url,{
            params:{
                categoryId:categoryId
            }
        })
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}


//PC 前端分页 限时拼团促销信息表   /blade-promotion/flashGroup/list
export const flashGroupList=(datas)=>{
    return new Promise((resolve,reject)=>{
        let url = '/api/blade-promotion/flashGroup/list'; 
        axios.get(url,{
            params:datas
        })
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err)
        })
    })
}